// import { Overlay, OverlayRef } from '@angular/cdk/overlay';
// import { TemplatePortal } from '@angular/cdk/portal';
// import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
// import {
//     ChangeDetectionStrategy,
//     ChangeDetectorRef,
//     Component,
//     inject,
//     OnDestroy,
//     OnInit,
//     Optional,
//     TemplateRef,
//     ViewChild,
//     ViewContainerRef,
//     ViewEncapsulation,
// } from '@angular/core';
// import { MatButton, MatButtonModule } from '@angular/material/button';
// import { MatIconModule } from '@angular/material/icon';
// import { MatTooltipModule } from '@angular/material/tooltip';
// import { Router, RouterLink } from '@angular/router';
// import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
// import { EMPTY, Observable, Subject, from, share, takeUntil, tap } from 'rxjs';


// import {
//     ActionPerformed,
//     PushNotificationSchema,
//     PushNotifications,
//     Token,
//   } from '@capacitor/push-notifications';
//   import { Capacitor } from '@capacitor/core';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { Notification } from 'app/core/graphql/graphql.service';
// import { FuseLoadingService } from '@fuse/services/loading';
     
//   const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');


// @Component({
//     selector: 'notifications',
//     templateUrl: './notifications.component.html',
//     encapsulation: ViewEncapsulation.None,
//     changeDetection: ChangeDetectionStrategy.OnPush,
//     exportAs: 'notifications',
//     standalone: true,
//     imports: [
//         MatButtonModule,
//         MatIconModule,
//         MatTooltipModule,
//         NgClass,
//         NgTemplateOutlet,
//         RouterLink,
//         DatePipe,
//     ],
// })
// export class NotificationsComponent implements OnInit, OnDestroy {
//     @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
//     @ViewChild('notificationsPanel')
//     private _notificationsPanel: TemplateRef<any>;

//     notifications: Notification[];
//     unreadCount: number = 0;
//     private _overlayRef: OverlayRef;
//     private _unsubscribeAll: Subject<any> = new Subject<any>();

//     private _snackBar = inject(MatSnackBar);


//     /**
//      * Constructor
//      */
//     constructor(
//         private _changeDetectorRef: ChangeDetectorRef,
//         private _notificationsService: NotificationsService,
//         private _overlay: Overlay,
//         private _viewContainerRef: ViewContainerRef,
//         private router : Router,
//         private _loadingService: FuseLoadingService
//         ) {

//         }

    

//     // -----------------------------------------------------------------------------------------------------
//     // @ Lifecycle hooks
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * On init
//      */
//     ngOnInit(): void {

    
//         if (isPushNotificationsAvailable) {
//             this.initializePushNotifications()
//         }
//         // Subscribe to notification changes
//         this._notificationsService.notifications$
//             .pipe(takeUntil(this._unsubscribeAll))
//             .subscribe((notifications: Notification[]) => {
//                 // Load the notifications
//                 this.notifications = notifications;

//                 // Calculate the unread count
//                 this._calculateUnreadCount();

//                 // Mark for check
//                 this._changeDetectorRef.markForCheck();
//             });

//         this._notificationsService.incommingNotificationSubscription().subscribe()
//     }

//     /**
//      * On destroy
//      */
//     ngOnDestroy(): void {
//         // Unsubscribe from all subscriptions
//         this._unsubscribeAll.next(null);
//         this._unsubscribeAll.complete();

//         // Dispose the overlay
//         if (this._overlayRef) {
//             this._overlayRef.dispose();
//         }
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Public methods
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * Open the notifications panel
//      */
//     openPanel(): void {
//         // Return if the notifications panel or its origin is not defined
//         if (!this._notificationsPanel || !this._notificationsOrigin) {
//             return;
//         }

//         // Create the overlay if it doesn't exist
//         if (!this._overlayRef) {
//             this._createOverlay();
//         }

//         // Attach the portal to the overlay
//         this._overlayRef.attach(
//             new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
//         );
//     }

//     /**
//      * Close the notifications panel
//      */
//     closePanel(): void {
//         this._overlayRef.detach();
//     }

//     /**
//      * Mark all notifications as read
//      */
//     markAllAsRead(): void {
//         // Mark all as read
//         this._notificationsService.markAllAsRead().subscribe();
//     }

//     /**
//      * Toggle read status of the given notification
//      */
//     toggleRead(notification: Notification): void {
//         // Toggle the read status
//         notification.read = !notification.read;

//         // Update the notification
//         this._notificationsService
//             .update(notification._id, notification)
//             .subscribe();
//     }

//     /**
//      * Delete the given notification
//      */
//     delete(notification: Notification): void {
//         // Delete the notification
//         this._notificationsService.delete(notification._id).subscribe();
//     }

//     /**
//      * Track by function for ngFor loops
//      *
//      * @param index
//      * @param item
//      */
//     trackByFn(index: number, item: any): any {
//         return item.id || index;
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Private methods
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * Create the overlay
//      */
//     private _createOverlay(): void {
//         // Create the overlay
//         this._overlayRef = this._overlay.create({
//             hasBackdrop: true,
//             backdropClass: 'fuse-backdrop-on-mobile',
//             scrollStrategy: this._overlay.scrollStrategies.block(),
//             positionStrategy: this._overlay
//                 .position()
//                 .flexibleConnectedTo(
//                     this._notificationsOrigin._elementRef.nativeElement
//                 )
//                 .withLockedPosition(true)
//                 .withPush(true)
//                 .withPositions([
//                     {
//                         originX: 'start',
//                         originY: 'bottom',
//                         overlayX: 'start',
//                         overlayY: 'top',
//                     },
//                     {
//                         originX: 'start',
//                         originY: 'top',
//                         overlayX: 'start',
//                         overlayY: 'bottom',
//                     },
//                     {
//                         originX: 'end',
//                         originY: 'bottom',
//                         overlayX: 'end',
//                         overlayY: 'top',
//                     },
//                     {
//                         originX: 'end',
//                         originY: 'top',
//                         overlayX: 'end',
//                         overlayY: 'bottom',
//                     },
//                 ]),
//         });

//         // Detach the overlay from the portal on backdrop click
//         this._overlayRef.backdropClick().subscribe(() => {
//             this._overlayRef.detach();
//         });
//     }

//     /**
//      * Calculate the unread count
//      *
//      * @private
//      */
//     private _calculateUnreadCount(): void {
//         let count = 0;

//         if (this.notifications && this.notifications.length) {
//             count = this.notifications.filter(
//                 (notification) => !notification.read
//             ).length;
//         }

//         this.unreadCount = count;
//     }





//     RegisterNotificationForMobile(){
//         // Request permission to use push notifications
//         // iOS will prompt user and return if they granted permission or not
//         // Android will just grant without prompting
//         PushNotifications.requestPermissions().then(result => {
//             if (result.receive === 'granted') {
//               // Register with Apple / Google to receive push via APNS/FCM
//               PushNotifications.register();
//             } else {
//               // Show some error
//             }
//           });
      
//           PushNotifications.addListener('registration', (token: Token) => {
    
    
//             this._notificationsService.updateFCMToken(token.value).subscribe()

//           });
      
//           PushNotifications.addListener('registrationError', (error: any) => {
//             // alert('Error on registration: ' + JSON.stringify(error));
//           });
      
//           PushNotifications.addListener(
//             'pushNotificationReceived',
//             (notification: PushNotificationSchema) => {

//                 this.openSnackBar(notification.title, 'Got it') 

//             //   alert('Push received: ' + JSON.stringify(notification));
//             },
//           );
      
//           PushNotifications.addListener(
//             'pushNotificationActionPerformed',
//             (notification: ActionPerformed) => {
//             //   alert('Push action performed: ' + JSON.stringify(notification));
//             },
//           );
//         }





//         private async initializePushNotifications() {
//             // Request permission
//             const result = await PushNotifications.requestPermissions();
            
//             if (result.receive === 'granted') {
//               // Register with Firebase
//               await PushNotifications.register();

//               PushNotifications.addListener('registration', (token: Token) => {
    
    
//                 this._notificationsService.updateFCMToken(token.value).subscribe()
    
//               });
        
//               // Handle notification when app is in foreground
//               PushNotifications.addListener('pushNotificationReceived',
//                 (notification: PushNotificationSchema) => {
//                   // Handle foreground notification
//                   console.log('Push notification received', notification);
//                   this.handleNotification(notification);
//                 }
//               );
        
//               // Handle notification when user taps on it
//               PushNotifications.addListener('pushNotificationActionPerformed',
//                 (action: ActionPerformed) => {
//                   // Handle notification tap
//                   console.log('Push notification action performed', action);
//                   this.handleNotificationTap(action.notification);
//                 }
//               );
//             }
//           }
        
//           private handleNotification(notification: PushNotificationSchema) {
//             // Handle the notification data
//             this.openSnackBar(notification.body, 'Got it') 
//           }
        
//           private async handleNotificationTap(notification: PushNotificationSchema) {
//             const data = notification.data;
//             try {
//                 // If it's just a path (starts with /)
//                 if (data.link.startsWith('/')) {
//                     this._loadingService.cshow()
//                   await this.router.navigateByUrl(data.link);
//                 }
//                 // If it's a full URL
//                 else {
//                   const url = new URL(data.link);
//                   if (url.hostname === 'pdcsafetrack.online') {
//                     this._loadingService.cshow()
//                     // Internal route - extract the path and query
//                     await this.router.navigateByUrl(url.pathname + url.search);
//                   } else {
//                     // External URL
//                     window.open(data.link, '_blank');
//                   }
//                 }
//               } catch (error) {
                
//                 console.error('Error handling notification click:', error);
//               }
//           }
        
//         openSnackBar(message: string, action: string) {
//             this._snackBar.open(message, action);
//           }
        
// }


import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { NotificationsService } from './notifications.service';
import { Subject, takeUntil } from 'rxjs';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Notification } from 'app/core/graphql/graphql.service';
import { FuseLoadingService } from '@fuse/services/loading';
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { environment } from 'environment/environment';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        DatePipe,
    ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    // ViewChild references
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    // Public properties
    notifications: Notification[];
    unreadCount: number = 0;
    isPushAvailable: boolean = Capacitor.isPluginAvailable('PushNotifications');
    platform: string = Capacitor.getPlatform();

    // Private properties
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _snackBar = inject(MatSnackBar);

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _router: Router,
        private _loadingService: FuseLoadingService
    ) {}

    // Lifecycle hooks
    async ngOnInit(): Promise<void> {
        // Initialize push notifications if available
        if (this.isPushAvailable) {
            await this.initializePushNotifications();
        }

         await this.requestPermissions();
     

        // Subscribe to notifications
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {
                this.notifications = notifications;
                this._calculateUnreadCount();
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to incoming notifications
        this._notificationsService.incommingNotificationSubscription().subscribe();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // Public methods
    openPanel(): void {
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        if (!this._overlayRef) {
            this._createOverlay();
        }

        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );
    }

    closePanel(): void {
        this._overlayRef?.detach();
    }

    markAllAsRead(): void {
        this._notificationsService.markAllAsRead().subscribe();
    }

    toggleRead(notification: Notification): void {
        notification.read = !notification.read;
        this._notificationsService
            .update(notification._id, notification)
            .subscribe();
    }

    delete(notification: Notification): void {
        this._notificationsService.delete(notification._id).subscribe();
    }

    // Private methods
    public async requestPermissions(): Promise<void> {
      await FirebaseMessaging.requestPermissions().then((status)=>{
        if (status.receive === 'granted'){
          this.getToken()
        }
      })
    }

    public async getToken(): Promise<void> {
      const options: GetTokenOptions = {
        vapidKey: environment.vapidKey,
      };
      if (Capacitor.getPlatform() === "web") {
        options.serviceWorkerRegistration =
          await navigator.serviceWorker.register("firebase-messaging-sw.js");
      }
      const { token } = await FirebaseMessaging.getToken(options);
      this._notificationsService.updateFCMToken(token).subscribe();
    }

    private async initializePushNotifications(): Promise<void> {
        try {
            // Request permissions
            const permResult = await PushNotifications.requestPermissions();
            if (permResult.receive !== 'granted') {
                console.log('Push notification permission denied');
                return;
            }

            // Register for push notifications
            await PushNotifications.register();

            // Add listeners
            this.setupPushListeners();

            // Additional iOS setup
            if (this.platform === 'ios') {
                await this.setupIOSNotifications();
            }
        } catch (error) {
            console.error('Error initializing push notifications:', error);
        }
    }

    private setupPushListeners(): void {
        // Registration success
        PushNotifications.addListener('registration', (token: Token) => {
            this._notificationsService.updateFCMToken(token.value).subscribe();
        });

        // Registration error
        PushNotifications.addListener('registrationError', (error: any) => {
            console.error('Push registration error:', error);
        });

        // Notification received
        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification: PushNotificationSchema) => {
                console.log('Push received:', notification);
                await this.handleForegroundNotification(notification);
            }
        );

        // Notification clicked
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (action: ActionPerformed) => {
                console.log('Push action performed:', action);
                await this.handleNotificationClick(action.notification);
            }
        );
    }

    private async setupIOSNotifications(): Promise<void> {
        try {
            await LocalNotifications.requestPermissions();
        } catch (error) {
            console.error('Error setting up iOS notifications:', error);
        }
    }

    private async handleForegroundNotification(notification: PushNotificationSchema): Promise<void> {
        try {
            if (this.platform === 'ios') {
                await LocalNotifications.schedule({
                    notifications: [{
                        id: Math.random() * 100000,
                        title: notification.title,
                        body: notification.body,
                        schedule: { at: new Date(Date.now()) },
                        extra: notification.data
                    }]
                });
            }

            this.showSnackBar(notification.title || 'New notification', 'View');
        } catch (error) {
            console.error('Error handling foreground notification:', error);
        }
    }

    private async handleNotificationClick(notification: PushNotificationSchema): Promise<void> {
        try {
            const data = notification.data;
            if (!data?.link) {
                return;
            }

            this._loadingService.cshow();

            if (data.link.startsWith('/')) {
                await this._router.navigateByUrl(data.link);
            } else {
                const url = new URL(data.link);
                if (url.hostname === 'pdcsafetrack.online') {
                    await this._router.navigateByUrl(url.pathname + url.search);
                } else {
                    window.open(data.link, '_blank');
                }
            }
        } catch (error) {
            console.error('Error handling notification click:', error);
        } finally {
            this._loadingService.chide();
        }
    }

    private showSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom'
        });
    }

    private _calculateUnreadCount(): void {
        this.unreadCount = this.notifications?.filter(n => !n.read).length ?? 0;
    }

    private _createOverlay(): void {
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
