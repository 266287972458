import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { MutationResult, SubscriptionResult } from 'apollo-angular';
import { DeleteNotificationGQL, GetNotificationsGQL, GetNotificationsQuery, MarkAllNotificationsAsReadGQL, NewNotificationGQL, NewNotificationSubscription, Notification, UpdateNotificationGQL, UpdateNotificationMutation, UpdateUserFcmTokenGQL } from 'app/core/graphql/graphql.service';
import { catchError, map, Observable, of, ReplaySubject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<
        Notification[]
    >(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _updateFCMToken :UpdateUserFcmTokenGQL,
        private _getNotifications: GetNotificationsGQL,
        private _IncommingNotifcation: NewNotificationGQL,
        private _updateNotification: UpdateNotificationGQL,
        private _deleteNotification: DeleteNotificationGQL,
        private _marrkNotification: MarkAllNotificationsAsReadGQL
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]> {
        return this.
        _getNotifications.fetch({unread:true})
            .pipe(
                tap((res:ApolloQueryResult<GetNotificationsQuery>) => {
                    const notifications = res.data.getNotifications
                    this._notifications.next(notifications);
                }), map(notifications => notifications.data.getNotifications),
                catchError(err =>{
                    console.log('error loading notifications');
                    return of([])
                })
            )
    }


    incommingNotificationSubscription(): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._IncommingNotifcation.subscribe().pipe(
                        map((res: SubscriptionResult<NewNotificationSubscription>) => {

                            const newNotification = res.data.newNotification;
                            console.log('New notification received: ', newNotification);
                            // Update the notifications with the new notification
                            this._notifications.next([
                                ...notifications,
                                newNotification,
                            ]);

                            // Return the new notification from observable
                            return newNotification;
                        })
                    )
            )
        );
    }
    

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .post<Notification>('api/common/notifications', {
                        notification,
                    })
                    .pipe(
                        map((newNotification) => {
                            // Update the notifications with the new notification
                            this._notifications.next([
                                ...notifications,
                                newNotification,
                            ]);

                            // Return the new notification from observable
                            return newNotification;
                        })
                    )
            )
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._updateNotification.mutate( { id, notification }).pipe(
                    map((result: MutationResult<UpdateNotificationMutation>) => {
                        const updatedNotification = result.data.updateNotification;
    
                        // Update notification in local state
                        const index = notifications.findIndex((item) => item._id === id);
                        notifications[index] = updatedNotification;
                        this._notifications.next(notifications);
                        return updatedNotification;
                    })
                )
            )
        );
    }
    

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._deleteNotification.mutate({
                    id
                }).pipe(
                    map((result: any) => {
                        const isDeleted = result.data.deleteNotification;
    
                        if (isDeleted) {
                            const index = notifications.findIndex((item) => item._id === id);
                            notifications.splice(index, 1);
                            this._notifications.next(notifications);
                        }
    
                        return isDeleted;
                    })
                )
            )
        );
    }
    
    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._marrkNotification.mutate().pipe(
                    map((result: any) => {
                        const isUpdated = result.data.markAllNotificationsAsRead;
    
                        if (isUpdated) {
                            notifications.forEach((notification) => notification.read = true);
                            this._notifications.next(notifications);
                        }
                        return isUpdated;
                    })
                )
            )
        );
    }
    

    updateFCMToken(token: string | void){
        if (token) {
           
            // cache the token in localStorage for

          
            return this._updateFCMToken.mutate({token}).pipe(
                map(({data}) => {
                    localStorage.setItem('cachedFCMToken', JSON.stringify({
                        data: token,
                        timestamp: Date.now()
                    }));
                    return data.updateUserFCMToken.succeed
                }),
                catchError((error) => {
                    return of(false)
                })
                
            )
        }
      
    }
}
