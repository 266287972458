<!-- <div class="app-container"> -->
  <div class="network-status-bar" [class.offline]="(isOnline$ | async) === false">
    <div class="status-message">
      <span *ngIf="(isOnline$ | async) === false" class="flex items-center gap-2">
        <mat-icon class="text-white">signal_wifi_off</mat-icon>
        You're offline. Some features may be unavailable.
      </span>

      <span *ngIf="(isOnline$ | async) === true" class="flex items-center gap-2">
        <mat-icon class="text-white">wifi</mat-icon>
        Back Online 
      </span>
    </div>
  </div>

<router-outlet></router-outlet>
<!-- </div> -->
<div *ngIf="updateStatus$ | async as status">
    <div *ngIf="status.status === 'downloading'" class="update-progress">
      Downloading update: {{status.progress / 100}}%
    </div>
</div>