import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap , from} from 'rxjs';
import { Network } from '@capacitor/network';
export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService)
        .check()
        .pipe(
            switchMap(({authenticated, isTokenAvailable}) => {
                // If the user is not authenticated...
                if (!authenticated) {
                    // Check network status using Capacitor
                    return from(Network.getStatus()).pipe(
                        switchMap((status) => {
                            if (!status.connected && isTokenAvailable) {
                                console.log('redicrect to offline')
                                return of(router.parseUrl('/offline'));
                            }
                            // Redirect to sign-in
                            const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                            return of(router.parseUrl(`sign-in?${redirectURL}`));
                        })
                    );
                }
                // Allow the access
                return of(true);
            })
        );
};
