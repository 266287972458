import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PageAccessGuard } from './core/auth/guards/pageAccess.guard';
import { PrivacyComponent } from './modules/public/privacy/privacy.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'example'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'example'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'verify', loadChildren: () => import('app/modules/auth/verify/verify.routes')}

        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
            {path: 'privacy',component: PrivacyComponent},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
        {path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes')},
        ]

    },
    {
        path: '',
        canActivate: [AuthGuard, PageAccessGuard],
        canActivateChild: [AuthGuard, PageAccessGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            //apps
            {path: 'employees', loadChildren: () => import('app/modules/admin/apps/employees/employees.routes')},
            {path: 'business', loadChildren: () => import('app/modules/admin/apps/business/business.routes')},
            {path: 'operations', loadChildren: () => import('app/modules/admin/apps/operations/operations.routes')},


               // Dashboards
            {
                path: 'dashboards',
                children: [
                {path: 'observationReport', loadChildren: () => import('app/modules/admin/dashboards/observationReport/observationReport.routes')},
         
            ]},



            
            //pages
            {path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.routes')}
        ]
    },





      // Pages full screen
      {path: 'pages', children: [
        // Error
        {
            path: 'error', children: [
            {path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.routes')},
            {path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.routes')},
            {path: 'offline', loadChildren: () => import('app/modules/landing/home/home.routes')},

            ]
    },

        
    ]}
];
