
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { getMainDefinition } from '@apollo/client/utilities';
import {  onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';




import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { Kind, OperationTypeNode } from 'graphql';
import { FuseLoadingService } from '@fuse/services/loading';


// const applink = 'api.pdcsafetrack.online'
// const uri = `https://${applink}/graphql`; // <-- add the URL of the GraphQL server here
// const wsURL =`wss://${applink}/graphql`



const applink = 'localhost:3000'
const uri = `http://${applink}/graphql`; // <-- add the URL of the GraphQL server here
const wsURL =`ws://${applink}/graphql`

export function apolloOptionsFactory(): ApolloClientOptions<any> {

  const errorLink = onError((error:any) => {

    console.log(error)

    const { graphQLErrors, operation, forward } = error;
    const networkError:HttpErrorResponse = error.networkError
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }


    // return of(networkError)  
    return forward(operation); // Optional: forward operation if needed
  })
  


  const httpLink = inject(HttpLink);
  const http = httpLink.create({ uri , extractFiles: (body) => extractFiles(body, isExtractableFile) }); // local dev


  const ws = new GraphQLWsLink(
    createClient({
        url: wsURL,
        connectionParams: async () => {
          const token = localStorage.getItem('accessToken')
          return {
            Authorization: `Bearer ${token}`,  // Send the token as part of connectionParams
          };
        },
        shouldRetry: () => true,
        retryWait: async function waitForServerHealthyBeforeRetry() {

          await new Promise((resolve) =>
            setTimeout(resolve, 1000 + Math.random() * 3000),
          );
        },
    }),
);

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: new HttpHeaders().set('Authorization', localStorage.getItem('AccessToken') || '')
    });

    return forward(operation);
  });

  const link = split(({ query }) => {
    const definition = getMainDefinition(query);
      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.operation === OperationTypeNode.SUBSCRIPTION
      );
  }, ws, http);

  const httpLinkWithErrorHandling = ApolloLink.from([
    errorLink,
    authMiddleware,
    link,
  ]);

  return {
    link: httpLinkWithErrorHandling,
    cache: new InMemoryCache(
      { addTypename: false, }

    ),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },

  };
}


export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
    deps: [HttpLink]
  },
];
