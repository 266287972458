import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { catchError, Observable, of, ReplaySubject, tap } from 'rxjs';
import { PagesService } from './pages.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private _httpClient = inject(HttpClient);
    private _pageService = inject(PagesService);
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
    //     return this._pageService.getUserAllowedPages().pipe(
    //         tap((navigation) => {
    //             this._navigation.next(navigation);
    //         })
    //     );
    // }
    return this._pageService.getUserAllowedPages().pipe(
        tap((navigation) => {
            this._navigation.next(navigation);
        }),
        catchError((error) => {
            // If offline, load cached navigation
            const cachedNav = localStorage.getItem('cachedNavigation');
            if (cachedNav) {
                this._navigation.next(JSON.parse(cachedNav).data);
                return of(JSON.parse(cachedNav).data);
            }
            throw error;
        })
    );
}
}
